import React from 'react';


export const NoDataFound = ({ text, icon }) => {
    return(
        <div className="noDataShow text-center p-4">
            <img src={icon} alt={icon} />
            <h6>{text}</h6>
        </div>
    )
}