import React, { useEffect, useState } from "react";
import { Container } from '@mui/material';
import { getCMSDetails } from "../../api/global-settings";
import { getS3BaseUrl } from "../../helpers/string_helper";

export default function Policy({ page }) {
    const [data, setData] = useState({})
    const fetchPageDetails = async (page) => {
        try {
            const resp = await getCMSDetails(page);
            setData(resp.data.data)
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        fetchPageDetails(page);
    }, [])
    return (
        <>
            <div className='manage_enlist_property'>
                <section>
                    <Container>
                        <div className="cms_page about">
                            <div className="d-flex flex-column cms_detail_section">
                                {data?.banner_image_url &&
                                    (<img src={`${getS3BaseUrl()}${data?.banner_image_url}`} alt="" style={{ maxHeight: '40vh', width: 'fit-content', margin: 'auto' }} />
                                    )}
                                <div className="cms_common_pg mt-2">
                                    <div className="cms_page_header">
                                        <h2 dangerouslySetInnerHTML={{ __html: data?.header }}>
                                        </h2>
                                    </div>
                                    <div className="cms_page_body" dangerouslySetInnerHTML={{ __html: data?.content }} >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    )
} 
