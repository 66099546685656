import React, { useState } from 'react';
import { Container, Box, Drawer } from '@mui/material';
import Logo from "../../assets/images/logo.png";
// import SmallLogo from "../../assets/images/logo-sm.png";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import Button from '@mui/material/Button';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Tabs, Tab, styled } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import { ROLES } from '../../constants/variables';
// const CustomTab = styled(Tab)(({ theme }) => ({
//   '&.Mui-selected': {
//     color: 'purple', // Active tab text color
//   }
// }));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

const Header = () => {
  const signInOptions = [ROLES.TENANT, ROLES.OWNER]
  // const drawerWidth = 240;
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const handleClickToProperty = () => {
    navigate("/enlist-your-property")
  }
  const backToHome = () => {
    navigate("/")
  }
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Handle opening the dropdown
  const handleClickDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg')); // Check for mobile view

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // const handleMenuOpen = (event) => {
  //     setMenuAnchorEl(event.currentTarget);
  // };

  const handleMenuClose = (role) => {
    window.location.href = process.env[`REACT_APP_${((role + "").toUpperCase())}_PORTAL_BASE_URL`];
  };

  return (
    <div className='header'>
      <Container>
        <div className='header_main d-flex justify-content-between align-items-center'>
          <div className='main_logo'>
            <img src={Logo} alt="logo" onClick={backToHome} />
            {/* <img src={ isSmallScreen ? SmallLogo : Logo} alt="logo" onClick={backToHome} /> */}
          </div>
          <div className="d-flex align-items-center">
            {isMobile ? (
              <>
                <div className='enlist_border'>
                  <div className="enlist_prop" onClick={handleClickToProperty}>
                    <HomeOutlinedIcon /> Enlist Property
                  </div>
                </div>
                {/* Mobile View: Hamburger Icon */}
                <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)} style={{ marginLeft: 'auto' }}>
                  <MenuIcon />
                </IconButton>

                {/* Mobile Drawer with Navigation Links */}
                <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} PaperProps={{ sx: { width: 200, paddingLeft: '16px', }, }}>
                  <List>
                    <ListItemButton onClick={toggleDrawer(false)}>
                      <NavLink className={({ isActive }) => (isActive ? 'activeLink' : 'tab_design')}
                        to="/"
                        style={({ isActive }) => ({
                          textDecoration: 'none',
                          color: isActive ? 'red' : 'black',
                          borderBottom: isActive ? '2px solid red' : 'none',
                        })}>
                        <ListItemText primary="Home" />
                      </NavLink>
                    </ListItemButton>
                    <ListItemButton onClick={toggleDrawer(false)}>
                      <NavLink className={({ isActive }) => (isActive ? 'activeLink' : 'tab_design')}
                        to="/about-us"
                        style={({ isActive }) => ({
                          textDecoration: 'none',
                          whiteSpace: 'nowrap',
                          color: isActive ? 'black' : 'red',
                          borderBottom: isActive ? '2px solid red' : 'none',
                        })}>
                        <ListItemText primary="About Us" />
                      </NavLink>
                    </ListItemButton>
                  </List>
                  <div>
                    <IconButton
                      className='theme_button sign_in_button'
                      aria-controls={open ? 'icon-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClickDropdown}
                    >
                      <AccountCircleOutlinedIcon className='me-2' /> <span>Sign In</span>
                      <ArrowDropDownIcon />
                    </IconButton>
                    <Menu
                      id="icon-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'icon-button',
                      }}
                      sx={{
                        '& .MuiPaper-root': {
                          backgroundColor: '#fff',
                          borderRadius: '8px',
                          border: '1px solid red !important'
                        },
                      }}
                    >
                      {signInOptions.map((role) => (
                        <MenuItem key={role} onClick={() => handleMenuClose(role)}>Sign in as {role}</MenuItem>
                      ))}
                    </Menu>
                  </div>
                </Drawer>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center me-3">
                  <List className="d-flex align-items-center">
                    <ListItem disablePadding>
                      <NavLink className={({ isActive }) => (isActive ? 'activeLink' : 'tab_design')}
                        to="/"
                        style={({ isActive }) => ({
                          textDecoration: 'none',
                          color: isActive ? 'red' : 'black',
                          borderBottom: isActive ? '2px solid red' : 'none',
                        })}>
                        <ListItemButton>
                          <ListItemText primary="Home" />
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <NavLink className={({ isActive }) => (isActive ? 'activeLink' : 'tab_design')}
                        to="/about-us"
                        style={({ isActive }) => ({
                          textDecoration: 'none',
                          color: isActive ? 'black' : 'red',
                          borderBottom: isActive ? '2px solid red' : 'none',

                        })}>
                        <ListItemButton>
                          <ListItemText primary="About Us" />
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                  </List>
                </div>
                <div className='enlist_border'>
                  <div className="enlist_prop" onClick={handleClickToProperty}>
                    <HomeOutlinedIcon /> Enlist Property
                  </div>
                </div>
                <div>
                  <IconButton
                    className='theme_button sign_in_button'
                    aria-controls={open ? 'icon-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickDropdown}>
                    <AccountCircleOutlinedIcon className="me-2" /> Sign In <ArrowDropDownIcon />
                  </IconButton>
                  <Menu
                    id="icon-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'icon-button',
                    }}
                    sx={{
                      '& .MuiPaper-root': {
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        border: '1px solid red !important'
                      },
                    }}
                  >
                    {signInOptions.map((role) => (
                      <MenuItem key={role} onClick={() => handleMenuClose(role)}>Sign in as {role}</MenuItem>
                    ))}
                  </Menu>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Header;