import React, { useState, useEffect, useMemo, useCallback } from "react";
import { debounce, TextField, Box, Autocomplete, InputAdornment } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SearchIcon from '@mui/icons-material/Search';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Chip from '@mui/material/Chip';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import SearchImgIcon from "../../assets/images/search.png";

import {
  setDefaults,
  fromPlaceId,
} from "react-geocode";
import { options } from "../../common/constant";
import { getAllProperties } from "../../api/property";


export default function ComboBox({ searchPropertyData, getFilter }) {
  const [isOption, setIsOption] = useState(0)
  const [googleData, setGoogleData] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [address, setAddress] = useState();
  const [update, setUpdate] = useState(0)
  const [isNear, setIsNear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState({
    latitude: '',
    longitude: ''
  })
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (googleData?.value?.place_id && !isMounted) {
      setDefaults({
        key: process.env.REACT_APP_GOOGLE_MAP_KEY, // Your API key here.
        language: "en", // Default language for responses.
        region: "in", // Default region for responses.
      });

      fromPlaceId(googleData?.value?.place_id)
        .then(({ results }) => {
          const { lat, lng } = results[0].geometry.location;
          setPosition({ latitude: lat, longitude: lng })
          fetchAllProperty({ latitude: lat, longitude: lng })
          setIsMounted(true)
        })
    } else if (!isMounted) {
      setPosition({ latitude: '', longitude: '' })
      fetchAllProperty()
    }
  }, [googleData?.value])

  const fetchAllProperty = async (query) => {
    try {
      getFilter(query)
      const response = (await getAllProperties(query)).data.data;
      searchPropertyData(response);
    } catch (err) {
      console.log(err)
    }
  }

  const getCurrentLocation = async () => {
    setIsLoading(true)
    setPosition({
      latitude: '',
      longitude: ''
    })
    await navigator.geolocation.getCurrentPosition(async function (position) {
      setPosition({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
      await fetchAllProperty({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
      fetch(url).then((res) => res.json()).then((data) => {
        if (isOption === 0) {
          setAddress({
            name: data?.display_name,
            id: 1
          })
        } else {
          setGoogleData(data?.display_name)
        }

        setUpdate(update + 1)
        setIsNear(true)
        setIsLoading(false)
      })
    });
  }

  const acValue = useMemo(() => {
    return options.find((option) => option.value === googleData?.value);
  }, [options, googleData?.name]);

  const getOptionsDelayed = useCallback(
    debounce((query) => {
      const search = query.toLowerCase().trim()
      const nameArray = options.filter((item) => (item.name?.toLocaleLowerCase().trim()).includes(search))
      if (nameArray?.length === 0) {
        setGoogleData(search)
        setIsOption(1)
        setIsOpen(true)
        setIsMounted(false)
      }
    }, 1200),
    []
  );

  useEffect(() => {
    if (isMounted) {
      getOptionsDelayed(searchQuery);
    }
  }, [searchQuery]);

  const resetNearMe = async () => {
    setIsNear(false)
    setGoogleData()
    setAddress()
    setPosition({ latitude: '', longitude: '' })
    fetchAllProperty()
    setUpdate(update + 1)
  }

  return (
    <div className='search_bar_broadness'>
      {isOption === 0 ? (
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          value={isNear ? address : acValue}
          options={options}
          key={update}
          isClearable={true}
          getOptionLabel={(option) => (option ? option.name : "")}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 }, width: '100%' }}
              key={option.id}
              {...props}
            >
              {option.name}
            </Box>
          )}
          onChange={(event, newValue) => {
            if (newValue === undefined) {
              setGoogleData()
              setIsMounted(false)
              setAddress()
            } else {
              setGoogleData(newValue)
            }
          }}
          onInputChange={(event, newValue) => {
            if (newValue?.length >= 4 && !isNear) {
              setIsMounted(true)
            }

            setSearchQuery(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search with your preferred location"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ color: '#ED3542' }}>
                    <Chip disabled={isLoading} style={isNear ? { backgroundColor: 'lightblue', cursor: 'pointer', marginRight: '10px' } : { cursor: 'pointer', marginRight: '10px' }} onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (isNear) {
                        resetNearMe()
                      } else {
                        getCurrentLocation()
                      }
                    }} icon={<LocationSearchingOutlinedIcon style={{ width: '16px' }} />} label="near me" />
                    <img src={SearchImgIcon} alt={SearchImgIcon} />
                  </InputAdornment>
                ),

              }}
            />
          )}
        />
      ) : (
        <div className="autocomplete-container" style={{ position: 'relative', textAlign: 'left', color: '#000', opacity: '1' }}>
          <PlaceOutlinedIcon style={{ position: 'absolute', left: 10, color: '#9e9e9e', zIndex: 9, top: '8px' }} />
          <GooglePlacesAutocomplete
            query={{
              componentRestrictions: {
                latLngBounds: { north: '22.567', south: '88.367', },
                region: 'in',
                strictBounds: true,
              },
              fields: ["address_components", "geometry", "icon", "name"],
              types: ["establishment"],
              language: 'en',
            }}
            minLengthAutocomplete={4}
            getDefaultValue={() => googleData?.value?.structured_formatting ? <div className="custom-autocomplete">{googleData?.value?.structured_formatting?.main_text}</div> : googleData}
            // renderRow={results => {
            //   if (results.value) {
            //     return (
            //       <>
            //         <PlaceOutlinedIcon />
            //         <span>{results.description}</span>
            //       </>
            //     );
            //   } else {
            //     return <span>{results.description}</span>
            //   }
            // }}
            // renderLeftButton={() => (
            //   <PlaceOutlinedIcon />
            // )}
            open={isOpen}
            isClearable={true}
            key={update}
            selectProps={{
              defaultInputValue: googleData?.value?.structured_formatting ? googleData?.value?.structured_formatting?.main_text : googleData,
              placeholder: "Search with your preferred location",
              name: "name",
              type: 'text',
              isClearable: true,
              onChange: (e) => {
                if (e === null) {
                  setIsOpen(false)
                  setIsMounted(false)
                }

                setGoogleData(e)
              },
              // components: {
              //   Input: CustomInput,
              // },
              styles: {
                input: (provided) => ({
                  ...provided,
                  paddingLeft: '40px', // Adjust to make room for the icon
                  paddingRight: '120px', // Adjust to make room for the chip
                }),
              },
            }}
            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          // className="custom-menu" 
          // sx={{
          //   '& .MuiOutlinedInput-root': {
          //     '& fieldset': {
          //       borderColor: 'transparent',
          //     },
          //     '&:hover fieldset': {
          //       borderColor: 'transparent',
          //     },
          //     '&.Mui-focused fieldset': {
          //       borderColor: 'transparent',
          //     },
          //   },
          // }}
          />
          <Chip
            disabled={isLoading} style={isNear ? {
              cursor: 'pointer',
              position: 'absolute',
              right: '0px',
              top: '50%',
              transform: 'translateY(-50%)',
              marginRight: '10px',
              backgroundColor: 'lightblue'
            } : {
              cursor: 'pointer',
              position: 'absolute',
              right: '0px',
              top: '50%',
              transform: 'translateY(-50%)',
              marginRight: '10px'
            }} onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (isNear) {
                resetNearMe()
              } else {
                getCurrentLocation()
              }
            }} icon={<LocationSearchingOutlinedIcon style={{ width: '16px' }} />} label="near me" />
          {/* <PlaceOutlinedIcon className="location-icon" /> */}
        </div>
      )}
    </div>
  );
}