import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, Stack, Button } from '@mui/material';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import LocalHotelOutlinedIcon from '@mui/icons-material/LocalHotelOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddressComponent from "../../components/Common/Address";
import { getS3BaseUrl } from "../../helpers/string_helper";
import { PropertySlider } from "../../components/Property/PropertyPictureSlider";
import { fetchBedType, fetchRoomType, selectAmenitiesIcon, selectGenderPreferenceIcon } from "../../components/Common/amenities_icon";
import { amber } from '@mui/material/colors';
import { FoodMenuDocumentSlider } from "../../components/Property/foodMenuSlider";
import SuitableTags from '../../components/Common/suitableTags';
import Amenities from "../../components/Common/amenitiesLanding";
import { socket } from "../../utils/socket";
import { PROPERTY_STATUS, SOCKET_EVENT } from "../../common/constant";

const PropertyListing = ({ propertyData, filterData }) => {
    const navigate = useNavigate();
    const [isFoodMenu, setIsFoodMenu] = useState(false);
    const [foodMenuFiles, setFoodMenuFiles] = useState();
    const [propertyList, setPropertyList] = useState([]);
    const s3BaseUrl = getS3BaseUrl();
    const [foodDocument, setFoodDocument] = useState()

    const toggleFoodMenu = () => {
        setIsFoodMenu(!isFoodMenu)
    }

    useEffect(() => {
        if (propertyData) {
            let isFoodMenuProperty = false
            const result = propertyData?.map((item) => {
                if (item.property?.id === foodMenuFiles?.id && isFoodMenu) {
                    isFoodMenuProperty = true
                }

                return {
                    ...item,
                    bedType: item.roomBedType.map((room) => {
                        return `${fetchRoomType(room.room_type_id)} ${fetchBedType(room.bed_count)}`
                    }),
                    property: {
                        ...item.property,
                        pictures: item.property?.pictures?.length ? item.property.pictures?.map((pic) => {
                            return { ...pic, location: `${s3BaseUrl}${pic?.location}` }
                        }) : [],
                        food_menu_documents: item.property?.food_menu_documents?.length ? item.property.food_menu_documents?.map((menu) => {
                            return { ...menu, location: `${s3BaseUrl}${menu?.location}` }
                        }) : [],
                        amenities: Array.isArray(item.property?.amenities) ? item.property.amenities : [],

                    }

                }
            })

            isFoodMenu && !isFoodMenuProperty && setIsFoodMenu(false)
            setPropertyList(result)
            if (filterData) {
                handleFilter(filterData, result)
            }
        }
    }, [propertyData]);

    const handleFilter = (data, properties) => {
        const priceRangeArray = data?.minBedPriceRange?.split('-');
        const filteredData = properties?.filter((el) => {
            if ((data.bedType ? el.bedType.includes(data.bedType) : true) && (data?.minBedPriceRange ? (parseInt(priceRangeArray[0]) <= parseInt(el.minBedPrice) && parseInt(priceRangeArray[1]) >= parseInt(el.minBedPrice)) : true)) {
                if (data?.near_to?.length > 0) {
                    const nearToArray = el.property?.near_to?.split(',')
                    const isNearTo = nearToArray?.filter(item => data.near_to.includes(item));
                    return isNearTo?.length > 0 ? el : ''
                } else {
                    return el;
                }
            }
        })
        setPropertyList(filteredData)
    }

    const avarageRating = (ratings) => {
        return ratings.reduce((acc, curr) => acc + curr.rating, 0) / ratings.length;
    }

    const handlePropertyClick = (property, scrollToReview = false) => {
        navigate('/property-detail', {
            state: {
                propertyId: JSON.stringify(property),
                scrollToReview
            }
        });
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleHiddenList = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENT.FOOD_MENU_EMIT, (data) => {
                if ((!foodDocument || foodDocument?.property_id !== data?.data?.property_id) && data?.data?.status === PROPERTY_STATUS.LIVE) {
                    setFoodDocument({
                        documents: data?.data?.documents,
                        property_id: data?.data?.property_id,
                        status: data?.data?.status
                    })
                }
            });
        }
    }, [socket]);

    useEffect(() => {
        if (foodDocument?.status === PROPERTY_STATUS.LIVE && foodDocument?.property_id) {
            const mapProperty = propertyList ? propertyList : propertyData
            const filterData = mapProperty?.map((item) => {
                if (item.property?.id === foodDocument?.property_id) {
                    const food_menu_document = []
                    if (foodDocument?.documents[0]?.extension) {
                        foodDocument?.documents?.forEach((menu) => {
                            food_menu_document.push({ ...menu, location: `${s3BaseUrl}${menu?.location}` })
                        })
                    }

                    const documents = foodDocument?.documents[0]?.extension && item.property?.food_menu_documents?.length > 0 ? [...item?.property?.food_menu_documents, ...food_menu_document] : (foodDocument?.documents[0]?.extension ? food_menu_document : foodDocument?.documents)
                    if (item.property?.id === foodMenuFiles?.id && isFoodMenu) {
                        setFoodMenuFiles({
                            ...item?.property,
                            food_menu_documents: documents
                        })
                        documents?.length === 0 && setIsFoodMenu(false)
                    }

                    return {
                        ...item,
                        property: {
                            ...item.property,
                            food_menu_documents: documents
                        }
                    }
                } else {
                    return item
                }
            })
            setPropertyList(filterData)
        }
    }, [foodDocument])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const proceedToSchedule = (property) => {
        return navigate("/schedule-form", {
            state: { property, }
        })
    }
    return (
        <div className="property_listing">
            {propertyList?.length > 0 && propertyList.map((item, index) => (
                <Card className="property_card" key={index}>
                    <div className="single_property_card">
                        <div className="slider-container property_img">
                            <PropertySlider pictures={item.property?.pictures} />
                        </div>
                        <div className="content-container">
                            <div className="property_header d-flex justify-content-between align-items-start">
                                <div>
                                    <h2 onClick={() => handlePropertyClick(item.property)} style={{ cursor: 'pointer' }}>{item.property?.name}</h2>

                                    {item.property.property_rating?.length > 0 ? (
                                        <Stack direction='row' alignItems="center" spacing={0.5} className='d-flex'>
                                            <div> {avarageRating(item.property.property_rating)} </div>
                                            <StarIcon sx={{ color: amber[500] }} />
                                            <div>
                                                {`Based on ${item.property.property_rating?.length} ratings `}
                                                <span
                                                    style={{ cursor: 'pointer', color: '#0752C3', 'text-decoration': 'underline' }}
                                                    onClick={() => handlePropertyClick(item.property, true)}
                                                >
                                                    {!!item?.property?._count.comments ? `(${item?.property?._count.comments} reviews)` : ''}
                                                </span>
                                            </div>
                                        </Stack>
                                    )
                                        : (<Stack direction='row' alignItems="center" spacing={0.5} className='d-flex my-2' style={{ fontSize: '14px' }}>
                                            {/* No rating found */}
                                        </Stack>)}

                                </div>

                                <div className="header_btns d-flex justify-content-between">
                                    {item?.property?.gender_preference && (
                                        <Button className='theme_button sign_in_button' variant="contained" style={{ zIndex: 2, marginLeft: '8px', cursor: 'none' }} >{selectGenderPreferenceIcon(item?.property?.gender_preference)}</Button>
                                    )}
                                    <div className="ribbons-wrapper">
                                        <span className="ribbon"> <LocalHotelOutlinedIcon />&nbsp; <span>{item?.vacantBedCount}</span>&nbsp;Beds Available</span>
                                    </div>
                                </div>
                            </div>
                            <div className="property_content">
                                <div className="main_location d-flex mb-2">
                                    {/* <RoomOutlinedIcon className="main_icon" /> */}
                                    <p className="body_font_sm mb-0"><AddressComponent address={item.property.address} /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${item?.property?.latitude},${item?.property?.longitude}&dir_action=navigate`} target="_blank" rel="noreferrer">Google Map View</a></p>
                                </div>
                                <div className="tags_block align-items-center mb-2" style={{
                                    display: item.property?.near_to ? 'flex' : 'none'
                                }}>
                                    <div className="tag_heading">
                                        {/* <MyLocationOutlinedIcon className="main_icon theme_color" /> */}
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_4268_10371)">
                                                <path d="M8.66699 0.666626L8.66766 2.70797C11.0798 3.00906 12.9916 4.92105 13.2924 7.33329H15.3337V8.66663L13.2923 8.66729C12.9913 11.0792 11.0796 12.9909 8.66766 13.292L8.66699 15.3333H7.33366V13.292C4.92141 12.9912 3.00943 11.0794 2.70833 8.66729L0.666992 8.66663V7.33329H2.70825C3.00907 4.92082 4.92119 3.00871 7.33366 2.70789V0.666626H8.66699ZM8.00033 3.99996C5.79119 3.99996 4.00033 5.79082 4.00033 7.99996C4.00033 10.2091 5.79119 12 8.00033 12C10.2095 12 12.0003 10.2091 12.0003 7.99996C12.0003 5.79082 10.2095 3.99996 8.00033 3.99996ZM8.00033 6.66663C8.73673 6.66663 9.33366 7.26356 9.33366 7.99996C9.33366 8.73636 8.73673 9.33329 8.00033 9.33329C7.26393 9.33329 6.66699 8.73636 6.66699 7.99996C6.66699 7.26356 7.26393 6.66663 8.00033 6.66663Z" fill="#ED3542" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4268_10371">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span className="font_bold font_black mx-2" style={{ fontSize: '14px' }}>Suitable For</span></div>
                                    <div>
                                        <SuitableTags tags={item?.property?.near_to?.split(',')} onClick={handleHiddenList} />
                                    </div>
                                </div>
                                {/* <div className="amenities" style={{ display: item.property?.amenities?.length ? 'flex' : 'none' }}>
                                    <ul className="list-inline d-flex align-items-center">
                                        {item.property?.amenities?.length > 0 && item.property?.amenities.map((am, amIndex) => (
                                            <li className="body_font_md" key={amIndex}> {selectAmenitiesIcon(am?.toLowerCase())} {am}</li>
                                        ))}
                                    </ul> */}
                                <div className="amenities" style={{ display: item.property?.amenities?.length ? 'flex' : 'none' }}>
                                    <Amenities tags={item.property?.amenities?.map(am => ({ amenity: am, icon: selectAmenitiesIcon(am?.toLowerCase()) }))} onClick={handleHiddenList} />

                                </div>
                                <div className="bed_type d-flex align-items-start">
                                    <div className="bed_type_header">
                                        <HotelOutlinedIcon className="theme_color me-0" /> <span style={{ fontSize: '14px' }} className="font_bold font_black mx-2">Bed Type:</span>
                                    </div>
                                    <ul className="list-inline d-flex mb-0">
                                        {item.roomBedType?.length > 0 && item.roomBedType.map((room, roomIndex) => (
                                            <li key={roomIndex} className={room.room_type_id === 1 ? "tag_block primary_block" : 'tag_block default_block'}>
                                                {item.bedType[roomIndex]}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="amount_block d-flex justify-content-between align-items-start">
                                    <div className="d-flex flex-column">
                                        <div className="amt d-flex align-items-center">
                                            <ShoppingBasketOutlinedIcon className="main_icon theme_color" />
                                            <span style={{ fontSize: '14px' }} className="body_font_sm font_bold font_black">Starts from</span>&nbsp;<span className="main_rate">₹{item.minBedPrice}</span>
                                        </div>
                                    </div>
                                    <div className="schedule_btns">
                                        {item.property?.food_menu_documents?.length > 0 && (
                                            <Button className='property_common_btn red_bordered_btn' onClick={() => {
                                                setFoodMenuFiles(item.property)
                                                toggleFoodMenu()
                                            }} ><RestaurantOutlinedIcon /> <span>Food Menu </span></Button>
                                        )}
                                        <Button className="property_common_btn red_bordered_btn" style={{ marginLeft: '10px' }} onClick={() => proceedToSchedule(item?.property)}><CalendarMonthOutlinedIcon /> <span>Schedule Visit</span></Button>
                                        <Button className="property_common_btn yellow_btn" onClick={() => handlePropertyClick(item?.property)}><RemoveRedEyeOutlinedIcon /><span>View Details</span></Button>


                                        {/* <div>
                                            {item?.property?.gender_preference && (
                                                <Button className='theme_button sign_in_button' variant="contained" style={{ zIndex: 2, marginLeft: '8px', cursor: 'none' }} >{selectGenderPreferenceIcon(item?.property?.gender_preference)}</Button>
                                            )}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            ))}

            {isFoodMenu && <FoodMenuDocumentSlider isOpen={isFoodMenu} handleClose={toggleFoodMenu} files={foodMenuFiles?.food_menu_documents} />}
        </div>
    );
}

export default PropertyListing;
