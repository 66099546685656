import PropTypes from 'prop-types';
import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes all
import { publicRoutes } from './routes/allRoutes';
import MainLayout from './components/Layouts/MainLayout';
// layouts Format
import './assets/scss/theme.scss';

function App(props) {
  // function getLayout() {
  //   let layoutCls = VerticalLayout;

  //   switch (props.layout.layoutType) {
  //     case 'horizontal':
  //       layoutCls = HorizontalLayout;
  //       break;
  //     default:
  //       layoutCls = VerticalLayout;
  //       break;
  //   }
  //   return layoutCls;
  // }

  // const Layout = getLayout();
  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={(
            <MainLayout>     
            { route.component }
            </MainLayout>
          )}
          key={idx}
          isAuthProtected={false}
        />
      ))}
      </Routes>
  );
}

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => ({
  layout: state.Layout,
});

export default connect(mapStateToProps, null)(App);
