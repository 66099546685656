import React from 'react';
import { Dashboard } from '../pages/Dashboard';
import { PropertyDetail } from '../pages/Detail';
import { BookRoom } from '../pages/Detail/bookRoom';
import BookForm from '../pages/Detail/bookingForm';
import { ScheduleForm } from '../pages/Detail/scheduleForm';
import { EnlistYourProperty } from '../pages/EnlistProperty';
import { OtpIndex } from '../pages/EnlistProperty/otpIndex';
import { PropertyRequest } from '../pages/EnlistProperty/propertyRequest';
import About from "../pages/About";
import Contact from "../pages/Contact";
import Policy from '../components/Common/Policy';
import { CMS_PAGES } from '../constants/variables';

const publicRoutes = [
  { path: '*', component: <Dashboard /> },
  { path: '/', component: <Dashboard /> },
  { path: '/property-detail', component: <PropertyDetail /> },
  { path: '/book-room', component: <BookRoom /> },
  { path: '/book-form', component: <BookForm /> },
  { path: '/schedule-form', component: <ScheduleForm /> },
  { path: '/enlist-your-property', component: <EnlistYourProperty /> },
  { path: '/otp-page', component: <OtpIndex /> },
  { path: '/property-request', component: <PropertyRequest /> },
  { path: `/${CMS_PAGES.ABOUT_US.split('_').join('-')}`, component: <About /> },
  { path: `/${CMS_PAGES.CONTACT_US.split('_').join('-')}`, component: <Contact /> },
];
// Adding policy pages
[CMS_PAGES.CANCEL_POLICY, CMS_PAGES.PRIVACY_POLICIES, CMS_PAGES.GUESTS_POLICY, CMS_PAGES.REFUND_POLICY, CMS_PAGES.TERMS_AND_CONDITION].map((key) => publicRoutes.push({ path: `/${key.split('_').join('-')}`, component: <Policy page={key} /> }))
export { publicRoutes };
