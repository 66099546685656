export const ucFirst = (str) => {
  if (typeof (str) !== 'string') {
    return ''
  }

  if (!str?.trim()) {
    return ''
  }

  return str.split(' ').map((item) => `${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`).join(' ')
}

export const ucFirstLetter = (str) => {
  if (typeof (str) !== 'string') {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}


export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getS3BaseUrl = () => {
  return `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/`;
}

export const handleKeyDown = (event, allRegex = null) => {
  if ([8, 9, 13].includes(event.keyCode)) {
    return;
  }

  const regex = allRegex ?? /^[0-9]+$/;

  if (!regex.test(event.key)) {
    event.preventDefault();
  }
}

export function endsWithAny(suffixes, string) {
  for (let suffix of suffixes) {
    if (string.endsWith(suffix))
      return true;
  }
  return false;
}

export const contactNoPattern = /^[0-9]{10}$/;