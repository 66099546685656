import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { Avatar, Card, CircularProgress, ListItem, ListItemAvatar, ListItemText, Rating, Typography } from '@mui/material';
import { getS3BaseUrl } from '../../helpers/string_helper';
import moment from 'moment/moment';
import { NoDataFound } from '../Common/NoDataFound';
import { fetchAllReviews } from '../../api/review';

function PropertyReviews({ propertyId, reviewCompHeight, scrollToReview }) {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [meta, setMeta] = useState({});
  const reviewsSection = useRef(null)

  const getAllReviews = useCallback(
    async (limit, page, refresh = false) => {
      setIsLoading(true);
      const paramData = {
        limit,
        page,
      };
      try {
        const responseActivity = await fetchAllReviews(propertyId, paramData);
        refresh ? setReviews(responseActivity?.data?.data?.data || "") :
          setReviews((old) => [
            ...old,
            ...(responseActivity?.data?.data?.data || ""),
          ]);
        setMeta(responseActivity?.data?.data?.meta);
      } catch (err) {
        toast.error(err.message);
      }
      finally {
        setIsLoading(false);
      }
    },
    [limit, page],
  )
  useEffect(() => {
    getAllReviews(limit, page);
  }, []);
  
  useEffect(() => {
    if (scrollToReview && !!reviewsSection?.current && reviews.length) {
      console.log('scroll', scrollToReview, reviewsSection?.current);
      console.log('reviews',  reviews);
      reviewsSection?.current?.scrollIntoView(true, { behavior: 'smooth', block: "start" })
    }
  }, [reviews]);

  const scrollHandler = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + clientHeight) < 2) {
      if (meta?.current_page < meta?.last_page) {
        getAllReviews(limit, page + 1);
        setPage((o) => o + 1);
      }
    }
  };

  return (
    <div
      style={{
        maxHeight: `${reviewCompHeight ? reviewCompHeight : 70
          }vh`,
        overflow: 'auto',
        marginTop: '2.2rem',
      }}
      onScroll={scrollHandler}
      className='hide-scroll'
    >
      <h2 ref={reviewsSection}>Reviews</h2>
      <Card >

        {reviews?.length === 0 && !isLoading && (
          <div style={{ textAlign: 'center' }}>
            <NoDataFound text={`No reviews to show`}
            />
          </div>
        )}
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {reviews?.length > 0 && reviews.map((item, index) => (
          <li
            key={index}
            className="d-flex align-items-start"
          >
            <ListItem alignItems="flex-start" >
              <ListItemAvatar>
                <Avatar
                  alt={item?.commenter?.name}
                  src={`${getS3BaseUrl()}${item?.commenter?.profile_photo}`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div className='d-flex flex-column review-comments'>
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ color: 'text.primary', display: 'inline' }}
                    >
                      {item?.commenter?.name}
                    </Typography>
                    <div className='opacity-75'>
                      {/* Local Guide */}
                    </div>
                    <span className='d-flex align-items-center'>
                      <Rating
                        className='me-2'
                        readOnly
                        sx={{ fontSize: "1rem" }}
                        value={item?.commenter?.tenant?.property_rating[0]?.rating}
                      />
                      <span className='opacity-75'>
                        {moment(item.created_at).utc().fromNow()}
                      </span>
                    </span>
                    <div className='opacity-75'>
                      {item?.content}
                    </div>
                  </div>
                }
                secondary={
                  <div className='mt-3'>
                    {item?.replies?.length > 0 && item?.replies.map((reply, index) =>
                      <div key={index} className='review_replies mt-1 d-flex justify-content-between align-items-center'>
                        {/* Reply */}
                        <div>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: 'text.primary', display: 'inline' }}
                          >
                            Response from {reply?.commenter?.name} ({reply?.commenter?.role?.role}) {moment(reply.created_at).utc().fromNow()}
                          </Typography>
                          <div>
                            {reply?.content}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                }
              />
            </ListItem>
          </li>
        ))}
      </Card>
    </div>
  );

}

export default PropertyReviews