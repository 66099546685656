export const ROLES = {
    TENANT: 'Tenant',
    EMPLOYEE: '',
    SUPER_ADMIN: 'Super Admin',
    OWNER: 'Owner',
    ADMIN: 'Admin',
    PROPERTY_MANAGER: 'Property Manager',
    PROSPECTING_OWNER: 'Prospecting Owner',
}

export const ACTIVE_STATUS = {
    ACTIVE: 'Active',
    IN_ACTIVE: 'Inactive'
}

export const NOTIFICATION_EVENTS = {
    TENANT_ASSIGNED_TO_BED_BY_ADMIN: {
        event: 'tenant_assigned_to_bed_by_admin',
        url: '/dashboard'
    },
    TENANT_BOOKED_BED_BY_SELF: {
        event: 'tenant_booked_bed_by_self', // TODO: implementation in controller due
        url: '/dashboard'
    },
    PG_LEAVING_REQUEST_BY_TENANT: {
        event: 'pg_leaving_request_by_tenant',
        url: '/dashboard'
    },
    TENANT_EVICTED_BY_ADMIN: {
        event: 'tenant_evicted_by_admin',
        url: '/pg-vacant-request'
    },
    COMPLAIN_STATUS_CHANGE: {
        event: 'complain_status_change',
        url: '/complain-ticket'
      },
    MESSAGE_RECEIVED: {
        event: 'message_received',
        url: '/message'
    },
    RENT_SLIP_PUBLISHED: {
        event: 'rent_slip_published',
        url: '/rent-payment-history'
    },
}

export const CMS_PAGES = {
    HOME: 'home',
    ENLIST_PROPERTY: 'enlist_property',
    ABOUT_US: 'about_us',
    CONTACT_US: 'contact_us',
    PRIVACY_POLICIES: 'privacy_policies',
    TERMS_AND_CONDITION: 'terms_and_condition',
    CANCEL_POLICY: 'cancel_policy',
    REFUND_POLICY: 'refund_policy',
    GUESTS_POLICY: 'guests_policy',
    SOCIAL_MEDIA: 'social_media',
};