import React, { useEffect, useState } from "react";
import { Container, Divider } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { getCMSDetails } from "../../api/global-settings";
import { getS3BaseUrl } from "../../helpers/string_helper";
import { CMS_PAGES } from "../../constants/variables";

export default function AboutUs() {
    const [data, setData] = useState({})
    const fetchPageDetails = async () => {
        try {
            const respAbout = await getCMSDetails(CMS_PAGES.ABOUT_US);
            const respContact = await getCMSDetails(CMS_PAGES.CONTACT_US);
            setData({ about: respAbout.data.data, contact: respContact.data.data })
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        fetchPageDetails();
    }, [])

    return (
        <>
            <div className='manage_enlist_property'>
                <section>
                    <Container>
                        <div className="cms_page about">
                            <div className="d-flex cms_detail_section">
                                {data?.about?.banner_image_url &&
                                    (<img src={`${getS3BaseUrl()}${data?.about?.banner_image_url}`} alt="" style={{ maxWidth: '50%', height: 'fit-content' }} />
                                    )}
                                <div className="cms_common_pg ms-2">
                                    <div className="cms_page_header">
                                        <h2 dangerouslySetInnerHTML={{ __html: data?.about?.header }}>
                                        </h2>
                                    </div>
                                    <div className="cms_page_body" dangerouslySetInnerHTML={{ __html: data?.about?.content }} >
                                    </div>
                                </div>
                            </div>
                            <Divider variant="middle" component="div" />
                            <div className="contact_block mt-2">
                                <h2 dangerouslySetInnerHTML={{ __html: data?.contact?.header }}></h2>
                                <ul className="list-inline">
                                    <li><FmdGoodOutlinedIcon /> <span dangerouslySetInnerHTML={{ __html: data?.contact?.address }}></span></li>
                                    <li><LocalPhoneOutlinedIcon /><span dangerouslySetInnerHTML={{ __html: data?.contact?.phone_number }}></span></li>
                                    <li><MailOutlinedIcon /><span dangerouslySetInnerHTML={{ __html: data?.contact?.email }}></span></li>
                                </ul>
                                {data?.contact?.content && (<div dangerouslySetInnerHTML={{ __html: data?.contact?.content }}></div>)}
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    )
} 
